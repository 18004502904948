import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import { rtypeN18r, rtypeT38sb, rtypeT84sb } from '../../styles/typography/presets';
import { colors } from '../../styles/colors';

const Text = styled.span`
      ${rtypeT38sb};
`;

const Name = styled.span`
  color: ${colors.grey};
  ${rtypeN18r};
`;

const OpeningQuote = styled.div`
  color: ${colors.pink};
  ${rtypeT84sb};
`;

const ClosingQuote = styled.div`
  color: ${colors.pink};
  ${rtypeT84sb};
`;

const CustomContainer = styled(Container)`
  margin-bottom: 30px;
  text-align: center;
  
  ${OpeningQuote} {
    grid-column: 5 / span 2;
    //margin-bottom: -0.25em;
  }
  
  ${Text} {
    grid-column: 2 / span 8;
    margin-bottom: 15px;
  }
  
  ${Name} {
    grid-column: 3 / span 6;
    margin-bottom: 15px;
  }
  
  ${ClosingQuote} {
    grid-column: 5 / span 2;
  }

  @media(min-width: ${breakpoints.lg}) {
    margin-bottom: 60px;
    
    ${Text} {
      grid-column: 3 / span 6;
      margin-bottom: 0;
    }
    
    ${Name} {
      grid-column: 3 / span 6;
      line-height: 84px;
    }
  }
`;

export default ({text, name, ...props}) => (
  <CustomContainer {...props}>
    <OpeningQuote>“</OpeningQuote>
    <Text>{text}</Text>
    <Name>{name}</Name>
    <ClosingQuote>”</ClosingQuote>
  </CustomContainer>
);