import React from 'react';
import { breakpoints, Container } from '../../styles/layout';
import styled from 'styled-components';
import { rtypeN18r, rtypeT21sb } from '../../styles/typography/presets';
import { colors } from '../../styles/colors';
import RichText from '../shared/RichText';


const Title = styled.div`
  ${rtypeT21sb};
  color: ${colors.orange};
`;

const BodyTitle = styled.div`
  ${rtypeT21sb};
  color: ${colors.orange};
`;

const Image = styled.div`
  text-align: center;
  img {
    width: 100%;
    height: auto;
    max-width: ${props => props.imageSize === 'Small' ? '150px' : '100%'};
  }
`;

const Paragraph = styled(RichText)`
  ${rtypeN18r};
  p {
    margin-bottom: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: inherit;
  }
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  ul, ol {
    margin-left: 1.5em;
    margin-bottom: 1.5em;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  iframe {
    width: 100%;
  }
`;


const CustomContainer = styled(Container)`
  margin-bottom: 60px;
  ${Title} {
    grid-column: 2 / span 8;
    margin-bottom: 15px;
  }
  ${Image} {
    grid-column: 2 / span 8;
    margin-bottom: 15px;
  }
  ${BodyTitle} {
    margin-bottom: 15px;
  }
  ${Paragraph} {
    grid-column: 2 / span 8;
  }

  @media(min-width: ${breakpoints.lg}) {
    margin-bottom: 90px;
    ${Title} {
      grid-column: 2 / span 4;
      margin-bottom: 0;
    }
    ${Image} {
      grid-column: 2 / span 4;
    }
    ${Paragraph} {
      grid-column: 6 / span 4;
    }
  }
`;

export default ({title, paragraph, image, image_size: imageSize, ...props}) => (
  <CustomContainer {...props}>
    {console.log('imageSize', imageSize)}
    {image?.url && (
      <Image imageSize={imageSize}>
        <img
          alt={image.alt}
          src={image.url}/>
      </Image>
    )}
    {title && !image?.url && <Title>{title}</Title>}
    <Paragraph>
      {title && image?.url && <BodyTitle>{title}</BodyTitle>}
      {paragraph}
    </Paragraph>
  </CustomContainer>
);
