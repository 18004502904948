import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../styles/layout';
import Excerpt from './slices/Excerpt';
import { RichText } from 'prismic-reactjs';
import Body from './slices/Body';
import Image from './slices/Image';
import PointBody from './slices/PointBody';
import Quote from './slices/Quote';
import { linkResolver } from '../resolvers';
import Embed from './slices/Embed';

const CustomContainer = styled(Container)`
  padding: 60px 0;
  > * {
    grid-column: 1 / span 10;
  }
  @media(min-width: ${breakpoints.lg}) {
    padding: 90px 0;
  }
`;


const SliceBody = ({postData, ...props}) => (
  <CustomContainer>
    {postData.body.map((slice, index) => {
      switch(slice.slice_type) {
        case ('excerpt'):
          return <Excerpt key={index} position={slice.primary.position}>{RichText.render(slice.primary.text, linkResolver)}</Excerpt>;
        case 'body':
          return <Body key={index} {...slice.primary} title={slice.primary.section_title} paragraph={RichText.render(slice.primary.paragraph, linkResolver)}/>;
        case 'image':
          return <Image key={index} image={slice.primary.image1} type={slice.primary.type}/>;
        case 'point_based_body':
          return <PointBody key={index} title={slice.primary.section_title} points={slice.items}/>;
        case 'quote':
          return <Quote key={index} text={RichText.render(slice.primary.text, linkResolver)} name={slice.primary.name}/>;
        case 'embed':
          return <Embed key={index} html={slice.primary.html}/>;
        default:
          return null;
      }
    })}
  </CustomContainer>
);

export default SliceBody;
