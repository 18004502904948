import React from 'react';
import { breakpoints, Container } from '../../styles/layout';
import styled from 'styled-components';
import { rtypeN18r, rtypeN21b, rtypeT21sb } from '../../styles/typography/presets';
import { colors } from '../../styles/colors';
import {RichText} from 'prismic-reactjs';
import { linkResolver } from '../../resolvers';


const Title = styled.div`
  ${rtypeT21sb};
  color: ${colors.orange};
`;

const PointTitle = styled.div`
  ${rtypeN21b};
`;

const PointBrief = styled.div`
  ${rtypeN18r};
`;

const Point = styled.div`
  ${rtypeN18r};
`;

const Points = styled.div`

`;

const CustomContainer = styled(Container)`
  margin-bottom: 60px;
  ${Title} {
    grid-column: 2 / span 8;
    margin-bottom: 15px;
  }
  ${Points} {
    grid-column: 2 / span 8;
    
    ${Point} {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media(min-width: ${breakpoints.lg}) {
    margin-bottom: 90px;
    ${Title} {
      grid-column: 2 / span 2;
      margin-bottom: 0;
    }
    ${Points} {
      grid-column: 4 / span 6;
      
      margin: -15px;
      display: flex;
      flex-wrap: wrap;
      
      ${Point} {
        margin-bottom: 0;
        width: 50%;
        padding: 15px;
      }
      
    }
  }
`;

export default ({title, points, ...props}) => (
  <CustomContainer {...props}>
    {title && <Title>{title}</Title>}
    <Points>
    {points.map((point, index) => (
      <Point key={index}>
        <PointTitle>{point.subtitle}</PointTitle>
        <PointBrief>{RichText.render(point.brief, linkResolver)}</PointBrief>
      </Point>
    ))}
    </Points>
  </CustomContainer>
);