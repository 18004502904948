import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import { rtypeN21bu, rtypeN18r, rtypeT84sb, typeN42r } from '../../styles/typography/presets';
import { colors } from '../../styles/colors';
import PropTypes from 'prop-types';
import FluidImg from './FluidImg';
import { fonts } from '../../styles/typography';


const Title = styled.h1`
  ${rtypeN21bu};
`;

const Message = styled.div`
  ${rtypeT84sb};
`;

const Arrow = styled.div`

`;

const Brief = styled.div`
  ${rtypeN18r};
  p {
    margin-bottom: 1.5em;
  }
`;

const Image = styled.div`
  .gatsby-image-wrapper {
    height: 100%;
    img {
      height: 100%;
    }
  }
`;

const GapComponent = styled.div`

`;

const GradientBackground = styled.div`
  background: linear-gradient(${props => props.reverse ? 'to bottom left' : 'to top right'}, ${props => props.bottomColor || colors.orange}, ${props => props.topColor || colors.pink});
`;

const CustomContainer = styled(Container)`
  grid-template-rows: repeat(6, auto);

  ${Title}, ${Message}, ${Brief} {
    opacity: 0.01;
    animation: fade-in-up 1s forwards ease-out 0s;
  }

  ${Image} {
    grid-column: 1 / span 10;
    grid-row: 1 / span 3;
    z-index: 0;
    height: 300px;
  }

  ${Title} {
    z-index: 1;
    grid-column: 2 / span 8;
    grid-row: 2;
    margin-top: 0;
    margin-bottom: 12px;
    color: ${colors.white};
  }

  ${Message} {
    z-index: 1;
    grid-column: 2 / span 8;
    grid-row: 3 / span 2;
    margin-top: 0;
    margin-bottom: 40px;
    color: ${colors.white};
  }

  ${GradientBackground} {
    z-index: 0;
    grid-column: 1 / span 10;
    grid-row: 4 / span 2;
  }

  ${Brief} {
    z-index: 1;
    grid-column: 3 / span 7;
    padding-left: 15px;
    grid-row: 5;
    margin-bottom: 70px;
    color: ${colors.white};
  }

  ${Arrow} {
    z-index: 1;
    grid-row: 5;
    grid-column: 2 / span 1;
    &:before {
      ${typeN42r};
      content: '↓';
      font-family: ${fonts.national.family};
      color: ${colors.white};
      position: relative;
      top: -0.2em;
    }
  }

  ${GapComponent} {
    grid-column: 2 / span 8;
    padding-top: 30px;
  }

  @media(min-width: ${breakpoints.lg}) {
    grid-template-rows: 90px auto auto 72px;
    ${Title} {
      z-index: 2;
      grid-column: ${props => props.showFullImage ? '2 / span 2' : '2 / span 3'};
      grid-row: 2 / span 1;
      align-self: flex-end;
      margin-bottom: 90px;
      position: relative;
      display: flex;
      align-items: center;
      &:after {
        content: '';
        margin-left: 30px;
        display: inline-block;
        background: ${colors.white};
        height: 2px;
        flex-grow: 1;
      }
    }
    ${Message} {
      z-index: 2;
      grid-column: ${props => props.showFullImage ? '4 / span 4' : '5 / span 4'};
      grid-row: 2 / span 1;
      align-self: flex-end;
      margin-bottom: 60px;
      margin-top: 110px;
    }
    ${Arrow} {
      display: none;
    }
    ${Brief} {
      z-index: 2;
      grid-column: 2 / span 4;
      grid-row: 3 / span 1;
      margin-bottom: 120px;
      padding-left: 0;
    }
    ${Image} {
      z-index: 0;
      grid-column: 5 / span 6;
      grid-row: ${props => props.largerGradient ? '1 / span 3' : '1 / span 4'};
      height: auto;
      img {
        border-bottom-left-radius: ${props => props.largerGradient ? '0' : '6px'};
      }
    }
    ${GradientBackground} {
      z-index: 1;
      grid-column: ${props => props.showFullImage ? '1 / span 5' : '1 / span 6'};
      grid-row: ${props => props.largerGradient ? '1 / span 4' : '1 / span 3'};
      border-bottom-right-radius: 6px;
    }
    ${GapComponent} {
      z-index: 1;
      grid-column: 2 / span 3;
      align-self: flex-end;
      padding-top: 0;
    }
  }
`;


const Hero = ({ title, message, brief, image, largerGradient, bottomColor, topColor, additionalComponent, reverseGradient, ...props }) => (
  <CustomContainer {...props} largerGradient={largerGradient}>
    <Title>{title}</Title>
    <Message>{message}</Message>
    <Brief dangerouslySetInnerHTML={{ __html: brief }}/>
    <Arrow/>
    <Image>
      <FluidImg
        image={image}
      />
    </Image>
    <GradientBackground reverse={reverseGradient} bottomColor={bottomColor} topColor={topColor}/>
    {additionalComponent && (
      <GapComponent>{additionalComponent}</GapComponent>
    )}
  </CustomContainer>
);

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  brief: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  largerGradient: PropTypes.bool,
};

export default Hero;
