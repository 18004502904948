import React from 'react';
import styled from 'styled-components';
import { rtypeT38sb } from '../../styles/typography/presets';
import { breakpoints, Container } from '../../styles/layout';


const Excerpt = styled.span`
  ${rtypeT38sb};
  p {
    margin-bottom: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const CustomContainer = styled(Container)`
  margin-bottom: 60px;
  ${Excerpt} {
    grid-column: 2 / span 8;
  }
  @media(min-width: ${breakpoints.lg}) {
    ${Excerpt} {
      grid-column: ${props => props.position === 'Left' ? '2 / span 6' : '4 / span 6'};
    }
  }
`;


export default (props) => (
  <CustomContainer {...props}>
    <Excerpt>
      {props.children}
    </Excerpt>
  </CustomContainer>
);