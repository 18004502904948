import React from 'react';
import { breakpoints, Container } from '../../styles/layout';
import styled from 'styled-components';
import { rtypeN18r } from '../../styles/typography/presets';
import RawHTML from '../shared/RawHTML';


const Embed = styled(RawHTML)`
  ${rtypeN18r};
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  p {
    margin-bottom: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: inherit;
  }
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  ul, ol {
    margin-left: 1.5em;
    margin-bottom: 1.5em;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
`;


const CustomContainer = styled(Container)`
  margin-bottom: 60px;
  ${Embed} {
    grid-column: 2 / span 8;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    margin-bottom: 90px;
  }
`;

export default ({html, ...props}) => (
  <CustomContainer {...props}>
    <Embed>
      {html}
    </Embed>
  </CustomContainer>
);