import React from 'react';
import Layout from '../components/layouts';
import { graphql } from 'gatsby';
import SliceBody from '../components/SliceBody';
import { Helmet } from 'react-helmet';
import { descriptionTags, imageTags, titleTags } from '../utilities/head-tags';
import Hero from '../components/shared/Hero';
import Breadcrumbs from '../components/blog/Breadcrumbs';
import routes from '../routes';


class ServicePage extends React.Component {
  componentDidMount() {
    window.analytics && window.analytics.page("Initiative", {
      title: `${this.props.data.prismicServicePage.data.title} | ${this.props.data.prismicSite.data.name}`,
      url: window.location.href,
      path: window.location.pathname,
    });
  }

  render() {
    const props = this.props;
    const data = props.data;
    const pageData = props.data.prismicServicePage.data;
    const pageDataRaw = JSON.parse(props.data.prismicServicePage.dataString);


    return (
      <Layout reverseLogo={true}>
        <Helmet>
          {titleTags(`${pageData.title} | ${data.prismicSite.data.name}`)}
          {descriptionTags(pageData.meta_description)}
          {imageTags(pageData.hero_image.url)}
        </Helmet>
        <Hero
          title={pageData.hero_message}
          message={pageData.title}
          brief={pageData.hero_brief.html}
          image={pageData.hero_image}
          additionalComponent={(
            <Breadcrumbs
              topLink={{ to: routes.initiatives, label: 'All Initiatives' }}
              childLink={{ label: pageData.title }}
            />
          )}
        />
        <SliceBody
          postData={pageDataRaw}
        />
      </Layout>
    );
  }
}

export default ServicePage;

export const query = graphql`
  query($slug: String!) {
    prismicSite {
      data {
        name
      }
    }
    prismicServicePage(uid: {eq: $slug}) {
      uid
      dataString
      data {
        title
        hero_image {
          alt
          copyright
          url
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
              
            }
          }
        }
        hero_brief {
          html
          text
        }
        hero_message
        meta_description
      }
    }
  }
`;