import React from 'react';
import { breakpoints, Container } from '../../styles/layout';
import styled from 'styled-components';


const Image = styled.div`
  img {
    width: 100%;
  }
`;

const CustomContainer = styled(Container)`
  margin-bottom: 60px;

  ${Image} {
    grid-column: 2 / span 8;
  }

  @media(min-width: ${breakpoints.lg}) {
    margin-bottom: 90px;
    ${Image} {
      grid-column: ${props => (props.type === 'Left') ? '2 / span 8' : (props.type === 'Right') ? '4 / span 6' : '2 / span 8'};
    }
  }
`;

export default ({image, ...props}) => (
  <CustomContainer {...props}>
    <Image>
      <img
        alt={image.alt}
        src={image.url}/>
    </Image>
  </CustomContainer>
)
