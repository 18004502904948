import React from 'react';


class RichText extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    const node = this.containerRef.current;
    node.querySelectorAll('a').forEach(el => {
      el.target = '_blank';
      el.rel = 'noopener noreferrer';
    });
  }

  render() {
    const { content, ...props } = this.props;

    if (content) return (
      <div
        {...props}
        ref={this.containerRef}
        dangerouslySetInnerHTML={{ __html: content }}/>
    );

    return (
      <div {...props} ref={this.containerRef}>
        {props.children}
      </div>
    );
  }
}

export default RichText;