import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import routes from '../../routes';
import { typeN16r } from '../../styles/typography/presets';
import { colors } from '../../styles/colors';
import pluralize from 'pluralize';
import { fonts } from '../../styles/typography';


const ParentLink = styled(Link)`
  ${typeN16r};
  color: ${colors.grey};
  text-decoration: none;
`;

const NodeLink = styled.span`
  ${typeN16r};
  color: ${colors.darkBlue};
`;

const Container = styled.div`
  ${ParentLink} {
    &:after {
      content: ' → ';
      font-family: ${fonts.national.family};
    }
  }
`;

const Breadcrumbs = ({topLink, middleLink, childLink, ...props }) => (
  <Container {...props}>
    <ParentLink to={topLink.to}>
      {topLink.label}
    </ParentLink>
    {middleLink && (
      <ParentLink to={middleLink.to}>
        {middleLink.label}
      </ParentLink>
    )}
    <NodeLink>
      {childLink.label}
    </NodeLink>
  </Container>
);

export default Breadcrumbs;