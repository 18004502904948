import routes from './routes';

export const linkResolver = function(doc){
  // Pretty URLs for known types
  if (doc.type === 'blog_post') return `${routes.journal}/${doc.uid}`;
  if (doc.type === 'service_page') return `${routes.initiatives}/${doc.uid}`;
  if (doc.type === 'standalone_page') {
    return `/${doc.uid}`;
  }
  // Fallback for other types, in case new custom types get created
  return "/doc/" + doc.id;
};